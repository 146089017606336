/* Add the following lines at the top of the file */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");
.fade-mask {
  mask-image: linear-gradient(to bottom, black 80%, transparent);
  -webkit-mask-image: linear-gradient(to bottom, black 60%, transparent);
}
